@import "cms-style";
//@import "../js/plugins/lightbox/lightbox.scss";

$main_font:'Inria Sans', sans-serif;
$accent_color1:#88c62a;
$white:#ffffff;
$black:#000000;
$font_color1:#090909;
$font_color2:#6f6f6f;

$dark_theme:#191919;

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin carousel_transition {
    -webkit-transition: ease 1s;
    -moz-transition: ease 1s;
    -ms-transition: ease 1s;
    -o-transition: ease 1s;
    transition: ease 1s;
}

@mixin carousel_transition15 {
    -webkit-transition: ease 1.5s;
    -moz-transition: ease 1.5s;
    -ms-transition: ease 1.5s;
    -o-transition: ease 1.5s;
    transition: ease 1.5s;
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    img{
        max-width: 100%;
    }
}

.default_font{
    font-size:16px;
    line-height: 30px;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 27px;
    }
    h1{
        font-size:80px;
        line-height: 100px;
        @media only screen and (max-width: 1500px) {
            font-size: 60px;
            line-height: 80px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 42px;
            line-height: 62px;
        }
    }
    h2{
        font-size:70px;
        line-height: 84px;
        @media only screen and (max-width: 1500px) {
            font-size: 50px;
            line-height: 64px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 36px;
            line-height: 50px;
        }
    }
    h3{
        font-size: 36px;
        line-height: 48px;
        @media only screen and (max-width: 1500px) {
            font-size: 30px;
            line-height: 42px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 36px;
        @media only screen and (max-width: 1500px) {
            font-size: 21px;
            line-height: 33px;
        }
    }
    h5{
        font-size: 21px;
        line-height: 33px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 30px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 28px;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
        color: $accent_color1;
        > li{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            h1,h2,h3,h4,h5,h6,ul,ol,p{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            > p{
                color: $font_color2;
                strong{
                    font-size: 18px;
                    color: $font_color1;
                }
            }
        }
    }
    ol{
        padding-left: 0;
    }
    h1,h2,h3,h4,h5,h6,ul,ol,p{
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.more_btn1{
    display: inline-block;
    padding: 0 40px;
    background: $accent_color1;
    @media only screen and (max-width: 991px) {
        padding: 0 20px;
    }
    span{
        line-height: 45px;
        color: $font_color1;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        padding-right: 39px;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("./../img/more_arr.svg");
    }
}

.page_header{
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    padding-top: 60px;
    @include anim;
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
        .logo_box{
            img{
                max-width: 100px;
            }
        }
    }
    &.smaller{
        padding-top: 30px;
        padding-bottom: 30px;
        background: $dark_theme;
        @media only screen and (max-width: 991px) {
            padding-top: 10px;
            padding-bottom: 10px;
            height: 40px;
        }
    }
    .navigation{
        padding-top: 5px;
        text-align: right;
        @media only screen and (max-width: 1200px) {
            padding-top: 0;
        }
        > ul{
            display: inline-block;
            vertical-align: top;
            > li{
                display: inline-block;
                vertical-align: middle;
                margin-right: 51px;
                font-size:14px;
                line-height: 14px;
                &.langs{
                    @media only screen and (max-width: 991px) {
                        margin-top: -40px;
                        margin-right: 30px;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 15px;
                            text-transform: uppercase;
                            @include anim;
                            font-size: 16px;
                            line-height: 16px;
                            &.active{
                                a{
                                    color:$accent_color1;
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                @media only screen and (max-width: 1200px) {
                    margin-right: 45px;
                }
                &.with_icon{
                    //padding-left: 27px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    &.phone{
                        //background-image: url("./../img/small_phone_icon.svg");
                    }
                    &.mail{
                        //background-image: url("./../img/small_mail_icon.svg");
                    }
                    &.social{
                        //background-image: url("./../img/social_icon1.svg");
                    }
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
                a{
                    color:$white;
                    @include anim;
                    &:hover{
                        color: $accent_color1;
                        text-decoration: none;
                    }
                }
                .menu_btn{
                    width: 26px;
                    height: 18px;
                    position: relative;
                    cursor: pointer;
                    @media only screen and (max-width: 991px) {
                        margin-top: -30px;
                    }
                    &:hover{
                        > div{
                            background: $accent_color1;
                        }
                    }
                    > div{
                        height: 2px;
                        background: $white;
                        position: absolute;
                        left: 0;
                        @include anim;
                        &:nth-child(1){
                            top: 0;
                            width: 100%;
                        }
                        &:nth-child(2){
                            top: 8px;
                            width: 100%;
                        }
                        &:nth-child(3){
                            top: 16px;
                            width: 16px;
                        }
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

.movie{
    height: 100vh;
    width: 100%;
    position: relative;
    background: $black;
    .image{
        opacity: 0.9;
        @include fill;
        @include bg_pos;
        background-size:cover;

        //video {
        //    @include fill;
        //}

        .element-with-video-bg {
            @include fill;
        }
    }
    .movie_cover{
        @include fill;
        > div{
            @include fill;
            @include bg_pos;
            background-position:center bottom;
            background-size:cover;
            //background-image:url("./../img/movie_cover.png");
            z-index: 2;
            .go_to_bottom{
                width: 100%;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 8vw;
                @media only screen and (max-width: 991px) {
                    bottom: 80px;
                }
                p{
                    display: inline-block;
                    font-size: 10px;
                    text-transform: uppercase;
                    cursor: pointer;
                    padding-top: 55px;
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-image: url("./../img/go_bottom_arr.svg");
                    color:#505050;
                    letter-spacing: 2px;
                }
            }
        }
        &:before{
            @include fill;
            content: '';
            @include bg_pos;
            background-position: center top;
            background-size: 100% auto;
            background-image:url("./../img/dark_cover1.png");
        }
        &:after{
            @include fill;
            content: '';
            @include bg_pos;
            background-position: center bottom;
            background-size: 100% auto;
            background-image:url("./../img/dark_cover2.png");
        }
    }
    .text_content{
        @include fill;
        @include vertical;
        .desc{
            margin-bottom: 8vw;
            text-align: center;
            color: $white;
            @media only screen and (max-width: 991px) {
                > .default_font{
                    display: none;
                }
            }
            header{
                margin-bottom: 50px;
            }
        }
    }
}

.carousel1{
    height: 597px;
    @media only screen and (max-width: 1500px) {
        height: 553px;
    }
    @media only screen and (max-width: 1300px) {
        height: 500px;
    }
    @media only screen and (max-width: 991px) {
        height: auto;
    }
}

.carousel2_box{
    height: 535px;
    @media only screen and (max-width: 1500px) {
        height: 470px;
    }
    @media only screen and (max-width: 1300px) {
        height: 440px;
    }
    @media only screen and (max-width: 991px) {
        height: auto;
    }

}

section.carousel1{
    padding-top: 100px;
    @media only screen and (max-width: 991px) {
        padding-top: 50px;
    }
    .carosuel_content{
        overflow: hidden;
        position: relative;
        .carousel_nav1{
            @include fill;
            height: 70%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
            .arr{
                position: absolute;
                width: 26%;
                height: 100%;
                top: 0;
                &.arr_left{
                    left: 0;
                }
                &.arr_right{
                    right: 0;
                }
                > div{
                    @include fill;
                    height: 60%;
                    @include vertical;
                    > div{
                        width: 100%;
                        text-align: center;
                        img{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        > ul{
            position: relative;
            white-space: nowrap;
            padding-top: 55px;
            @media only screen and (max-width: 991px) {
                padding-top: 0;
            }
            > li{
                box-sizing: content-box;
                display: inline-block;
                vertical-align: top;
                width: 26%;
                padding-right: 11%;
                white-space: normal;

                @include carousel_transition;

                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 5%;
                }
                &:hover{
                    a{
                        text-decoration: none;
                    }
                }
                @media only screen and (min-width: 991px) {
                    &.before_active, &.after_active{
                        .image{
                            margin-bottom: 47px;
                            opacity: 0.3;
                            box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
                            order: 1;



                            .short_desc_holder{
                                visibility: hidden;
                                opacity: 0;
                            }
                        }
                        .desc{
                            color: #acacac;
                            order: 2;
                            p{
                                padding-top: 15px;
                                position: relative;
                                &:after{
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    width: 44px;
                                    height: 2px;
                                    margin: 0 auto;
                                    background: #dedede;
                                }
                            }
                        }
                    }
                }
                @media only screen and (max-width: 990px) {
                    &.before_active, &.after_active{
                        position: relative;
                        z-index: 2;
                        .carousel_box{
                            .desc{
                                order: 1;
                            }
                            .image{
                                width: 100%;
                                margin-left: 0;
                                padding-bottom: 102%;
                                order:2;
                                .short_desc_holder{
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                            .desc{
                                color: $accent_color1;
                                position: relative;
                                padding-bottom: 25px;
                                position: relative;
                                top: -55px;
                                .default_font{
                                    p{
                                        font-size: 21px;
                                    }
                                }
                                &:after{
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    width: 44px;
                                    height: 2px;
                                    margin: 0 auto;
                                    background: $accent_color1;
                                }
                            }
                        }
                    }
                }
                &.active{
                    position: relative;
                    z-index: 2;
                    //margin-top: 60px;
                    @media only screen and (max-width: 1500px) {
                        margin-top: 0px;
                    }
                    .carousel_box{
                        .image{

                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;

                            margin-top:0;
                            opacity:1;
                            width: 200%;
                            margin-left: -50%;
                            padding-bottom: 102%;
                            //order:2;/
                            @media only screen and (max-width: 1200px) {
                                padding-bottom: 130%;
                            }
                            @media only screen and (max-width: 991px) {
                                //padding-bottom: 102%;
                                padding-bottom: 100%;
                            }
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                margin-left: 0;
                            }
                            .short_desc_holder{
                                opacity: 1;

                                .short_desc {
                                    opacity:1;
                                }
                            }
                        }
                        .desc_top {
                            opacity:1;
                        }

                        .desc {
                            order: 1;
                            opacity:0;
                        }
                    }
                }
                .carousel_box{

                    background-color:#fff;
                    width: 100%;
                    display: flex;
                    flex-flow: column;

                    @include carousel_transition;

                    > div{
                        align-items: stretch;
                    }
                    .image{

                        @include carousel_transition;

                        -webkit-box-shadow: 0px 10px 40px -15px rgba(0,0,0,1);
                        -moz-box-shadow: 0px 10px 40px -15px rgba(0,0,0,1);
                        box-shadow: 0px 10px 40px -15px rgba(0,0,0,1);

                        opacity:0.3;
                        margin-top:-16%;
                        @include bg_pos;
                        background-size: cover;
                        width: 100%;
                        height: 0;
                        padding-bottom: 60.6%;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            margin-top: 0;
                            padding-bottom: 100%;
                        }
                        .short_desc_holder{
                            @include anim;
                            @include carousel_transition;

                            opacity:0;
                            position: absolute;
                            top: 25px;
                            left: 25px;
                            width: calc(100% - 50px);
                            height: calc(100% - 50px);
                            border: 25px solid rgba(136, 198, 42, 0.84);
                            @media only screen and (max-width: 991px) {
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
                            .short_desc{

                                @include carousel_transition15;

                                opacity:0;
                                position: absolute;
                                top: 9px;
                                left: 9px;
                                width: calc(100% - 18px);
                                height: calc(100% - 18px);
                                background: rgba(136, 198, 42, 0.84);
                                @include vertical;
                                > div{
                                    text-align: center;
                                    font-size: 18px;
                                    line-height: 33px;
                                    @media only screen and (max-width: 1500px) {
                                        font-size: 13px;
                                        line-height: 23px;
                                    }
                                    color:$white;
                                    padding: 0 11%;
                                    width: 100%;
                                    @media only screen and (max-width: 991px) {
                                        padding: 0;
                                    }
                                    p{
                                        //margin-bottom: 12%;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        @media only screen and (max-width: 1200px) {
                                            margin-bottom: 4%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .desc{
                        text-align: center;
                        opacity:1;

                        @include carousel_transition;
                    }
                    .desc_top{

                        @include carousel_transition;

                        text-align:center;
                        opacity:0;
                        color:$black;
                        position: relative;
                        padding-bottom: 25px;
                        position: relative;
                        margin-bottom:25px;
                        //top: -55px;
                        @media only screen and (max-width: 1200px) {
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                        }
                        .default_font{
                            p{
                                font-size: 21px;
                                @media only screen and (max-width: 1200px) {
                                    font-size: 18px;
                                }
                            }
                        }
                        &:after{
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            width: 44px;
                            height: 2px;
                            margin: 0 auto;
                            background: $black;
                        }
                    }
                }
            }
        }
    }
}

.carousel2{
    .carousel2_box{
        position: relative;
        .carousel_nav1{
            @include fill;
            height: 70%;
            @media only screen and (max-width: 991px) {
                height: 0;
            }
            .arr{
                position: absolute;
                width: 26%;
                height: 100%;
                top: 0;
                &.arr_left{
                    left: 0;
                    > div{
                        > div{
                            text-align: left;
                            img{
                                margin-left: -37px;
                            }
                        }
                    }
                }
                &.arr_right{
                    right: 0;
                    > div{
                        > div{
                            text-align: right;
                            img{
                                margin-right: -37px;
                            }
                        }
                    }
                }
                > div{
                    @include fill;
                    @include vertical;
                    > div{
                        width: 100%;
                        img{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .carosuel_content{
        overflow: hidden;
        .carousel_nav1{
            @include fill;
            height: 70%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
            .arr{
                position: absolute;
                width: 26%;
                height: 100%;
                top: 0;
                &.arr_left{
                    left: 0;
                }
                &.arr_right{
                    right: 0;
                }
                > div{
                    @include fill;
                    @include vertical;
                    > div{
                        width: 100%;
                        text-align: center;
                        img{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        > ul{
            position: relative;
            white-space: nowrap;
            padding-top: 55px;
            @media only screen and (max-width: 991px) {
                padding-top: 0;
            }
            > li{
                display: inline-block;
                vertical-align: top;
                width: 26%;
                padding-right: 11%;
                box-sizing: content-box;
                white-space: normal;
                //@include anim;
                @include carousel_transition;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 5%;
                }
                &:hover{
                    a{
                        text-decoration: none;
                    }
                }
                @media only screen and (min-width: 991px) {
                    &.before_active, &.after_active{
                        .image{
                            order: 1;
                            .short_desc_holder{
                                @include fill;
                                @include vertical;
                                color:#a0a0a0;
                                .short_desc{
                                    width: 100%;
                                    text-align: center;
                                    > div{
                                        .title{
                                            font-size: 18px;
                                            line-height: 48px;
                                        }
                                        .city{
                                            font-size: 12px;
                                        }
                                        .more_btn1{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .desc{
                            color: #acacac;
                            order: 2;
                            p{
                                padding-top: 15px;
                                position: relative;
                                &:after{
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    width: 44px;
                                    height: 2px;
                                    margin: 0 auto;
                                    background: #dedede;
                                }
                            }
                        }
                    }
                }
                @media only screen and (max-width: 990px) {
                    &.before_active, &.after_active{
                        position: relative;
                        z-index: 2;
                        .carousel_box{
                            .desc{
                                order: 1;
                            }
                            .image{
                                width: 100%;
                                margin-left: 0;
                                padding-bottom: 102%;
                                order:2;
                                .cover{
                                    opacity: 0;
                                    visibility: hidden;
                                }
                                .short_desc_holder{
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border: 25px solid rgba(136, 198, 42, 0.84);
                                    color: $font_color1;
                                    .short_desc{
                                        position: absolute;
                                        top: 9px;
                                        left: 9px;
                                        width: calc(100% - 18px);
                                        height: calc(100% - 18px);
                                        background: rgba(136, 198, 42, 0.84);
                                        @include vertical;
                                        padding-top: 30px;
                                        @media only screen and (max-width: 1200px) {
                                            padding-top: 0;
                                        }
                                        > div{
                                            text-align: center;
                                            font-size: 18px;
                                            line-height: 33px;
                                            padding: 0 11%;
                                            width: 100%;
                                            .title{
                                                font-size: 36px;
                                                line-height: 48px;
                                                p{
                                                    font-weight: 700;
                                                }
                                            }
                                            .city{
                                                font-size: 18px;
                                                margin-bottom: 8%;
                                                @media only screen and (max-width: 1200px) {
                                                    margin-bottom: 4%;
                                                }
                                            }
                                            .more_btn1{
                                                display: inline-block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.active{
                    position: relative;
                    z-index: 2;
                    margin-top: 60px;
                    @media only screen and (max-width: 991px) {
                        margin-top: 0;
                    }
                    .carousel_box{
                        .image{
                            width: 200%;
                            margin-left: -50%;
                            padding-bottom: 102%;
                            @media only screen and (max-width: 1200px) {
                                padding-bottom: 130%;
                            }
                            @media only screen and (max-width: 991px) {
                                //padding-bottom: 102%;
                                padding-bottom: 100%;
                                width: 100%;
                                margin-left: 0;
                            }
                            .cover{
                                opacity: 0;
                                visibility: hidden;
                            }
                            .short_desc_holder{
                                opacity:1;
                                .short_desc {
                                    opacity:1;
                                    > div {
                                        opacity:1;
                                        .title{
                                            opacity:1;
                                        }
                                        .city{
                                            opacity:1;
                                        }
                                        .more_btn1{
                                            opacity:1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .carousel_box{

                    @include carousel_transition;

                    width: 100%;
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                    }
                    .image{

                        @include carousel_transition;

                        @include bg_pos;
                        background-size: cover;
                        width: 100%;
                        height: 0;
                        padding-bottom: 60.6%;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            padding-bottom: 100%;
                        }
                        .short_desc_holder{

                            @include carousel_transition;

                            opacity:0;
                            position: absolute;
                            top: 25px;
                            left: 25px;
                            width: calc(100% - 50px);
                            height: calc(100% - 50px);
                            border: 25px solid rgba(136, 198, 42, 0.84);
                            color: $font_color1;
                            @media only screen and (max-width: 991px) {
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
                            .short_desc{

                                @include carousel_transition;
                                position: absolute;
                                top: 9px;
                                left: 9px;
                                width: calc(100% - 18px);
                                height: calc(100% - 18px);
                                background: rgba(136, 198, 42, 0.84);
                                @include vertical;
                                padding-top: 30px;
                                @media only screen and (max-width: 1200px) {
                                    padding-top: 0;
                                }
                                > div{
                                    @include carousel_transition15;
                                    text-align: center;
                                    font-size: 18px;
                                    line-height: 33px;
                                    padding: 0 11%;
                                    width: 100%;
                                    opacity:0;
                                    .title{
                                        @include carousel_transition15;
                                        font-size: 36px;
                                        line-height: 48px;
                                        opacity:0;
                                        p{
                                            font-weight: 700;
                                        }
                                    }
                                    .city{
                                        @include carousel_transition15;
                                        font-size: 18px;
                                        margin-bottom: 8%;
                                        opacity:0;
                                        @media only screen and (max-width: 1200px) {
                                            margin-bottom: 4%;
                                        }
                                    }
                                    .more_btn1{
                                        @include carousel_transition15;
                                        opacity:0;
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                        .cover{
                            @include carousel_transition;
                            //@include anim;
                            @include fill;
                            background: rgba(0,0,0,0.7);
                        }
                    }
                    .desc{
                        text-align: center;
                    }
                }
            }
        }
    }
}

.stuff{
    margin-bottom: 100px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
}

.section_header{
    margin-bottom: 50px;
    color: $font_color1;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    > .row{
        > div{
            float: none;
            display: inline-block;
            vertical-align: middle;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
    }
    &.white{
        color: $white;
    }
    &.underline{
        position: relative;
        padding-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 40px;
        }
        &.text-center{
            &:after{
                right: 0;
                margin: 0 auto;
            }
        }
        &:after{
            content: '';
            width: 90px;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 2px solid $accent_color1;
            @media only screen and (max-width: 991px) {
                right: 0;
                margin: 0 auto;
            }
        }
    }
    .default_font{
        u{
            text-decoration:none;
            position: relative;
            &:after{
                position: absolute;
                top: 100%;
                left: 0;
                content: '';
                width: 100%;
                height: 15px;
                background: #88c62a;
                margin-top: -21px;
                z-index: -1;
            }
        }
    }
}

.features{
    > .container {
        position: relative;
        z-index: 2;
    }
    .short_desc{
        color: $font_color2;
    }
}

.first_col{
    @media only screen and (max-width: 1500px) {
        position: relative;
        bottom: -50px;
    }
}

.hover_section{
    position: relative;
    padding: 150px 0;
    @media only screen and (max-width: 1400px) {
        padding-top: 50px;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0 0 0;
    }
    .hover_bg{

        margin-top: -4%;

        @include fill;
        @include bg_pos;
        background-size:cover;
        @media only screen and (max-width: 1400px) {
            background-size: contain;
            margin-top: -7%;
        }
        @media only screen and (max-width: 1200px) {
            margin-top: -9%;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }

        svg {
            width:100%;

            .svg_bg_ico {
                opacity:0;
            }
        }

        .hover_bg_txt {
            position: absolute;
            //top: 50.4%;
            top: 44.4%;
            left: 40%;
            font-size: 26px;
            line-height: 157%;
            width: 20%;
            font-weight: 600;
            text-align: center;
            //
            //@media only screen and (max-width: 1500px) {
            //    font-size:23px;
            //    top:52%;
            //}
            @media only screen and (max-width: 1300px) {
                font-size: 18px;
                top: 46.4%;
            }
        }
    }
    .hover_section_content{
        position: relative;
        z-index: 2;
        .first_row{
            position: relative;
            top: -60px;
            @media only screen and (max-width: 1500px) {
                top: -130px;
            }
            @media only screen and (max-width: 1400px) {
                top: -60px;
            }
            @media only screen and (max-width: 991px) {
                top: 0;
            }
        }
        .margin{
            margin-bottom: 102px;
            padding-bottom: 102px;
            @media only screen and (max-width: 1300px) {
                margin-bottom: 0;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .hover_box{
            width: 400px;
            margin: 0 auto;
            @media only screen and (max-width: 1200px) {
                width: 300px;
            }
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
            &.small{
                width: 280px;
            }
            .hover_box_top{
                margin-bottom: 10px;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    &.icon{
                        width: 74px;
                        height: 0;
                        padding-bottom: 74px;
                        border-radius: 50%;
                        background-color: $accent_color1;
                        @include bg_pos;
                        box-shadow: 0px 10px 25px 0px rgba(106, 99, 96, 0.29);
                    }
                    &.desc{
                        width: calc(100% - 74px);
                        padding-left: 25px;
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
            }
            .hover_box_bottom{
                .desc{
                    .default_font{
                        color: $font_color2;
                    }
                }
            }
        }
    }
}

.about{
    padding-top: 55px;
    padding-bottom: 70px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 45px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 0;
    }
    .stuff_list{
        margin-bottom: 0;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    .container{
        > .row{
            margin:0 -75px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -25px;
            }
            > div{
                padding: 0 75px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 25px;
                }
            }
        }
    }
    .short_desc{
        margin-bottom: 70px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 45px;
        }
        .default_font{
            color: $font_color2;
        }
    }
}

.stuff_list{
    margin-bottom: 70px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 45px;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    > ul{
        @media only screen and (max-width: 991px) {
            display: inline-block;
        }
        > li{
            display: inline-block;
            vertical-align: top;
            font-size:12px;
            line-height: 18px;
            color:$font_color1;
            padding-left: 43px;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url("./../img/check_icon.svg");
            margin-right: 90px;
            text-align: left;
            @media only screen and (max-width: 1500px) {
                margin-right: 45px;
            }
            @media only screen and (max-width: 1200px) {
                margin-right: 0;
                margin-bottom: 30px;
                width: 100%;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            p{
                font-weight: 700;
            }
        }
    }
}

.why_we{
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    .move_bg{
        @include fill;
        @media only screen and (max-width: 991px) {
            display: none;
        }
        .bg{
            @include fill;
            @include bg_pos;
            &.bg1{
                background-image: url("./../img/move_bg1.png");
            }
            &.bg2{
                background-image: url("./../img/move_bg2.png");
            }
            &.bg3{
                background-image: url("./../img/move_bg3.png");
            }
            &.bg4{
                background-image: url("./../img/move_bg4.png");
            }
            &.bg5{
                background-image: url("./../img/move_bg5.png");
            }
            &.bg6{
                background-image: url("./../img/move_bg6.png");
            }
        }
    }
    .container{
        position: relative;
        z-index: 2;
    }
    .desc{
        margin-right: 30%;
        @media only screen and (max-width: 1200px) {
            margin-right: 0;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        .short_desc{
            margin-bottom: 70px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 45px;
            }
            .default_font{
                color: $font_color2;
            }
        }
    }
    .image{
        margin-top: 9%;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        @include bg_pos;
        background-size: cover;
        box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
        .cover{
            @include fill;
            @include vertical;
            cursor: pointer;
            > div{
                width: 100%;
                text-align: center;
            }
        }
    }
}

.relations{
    background-color: $dark_theme;
    padding:63px 0;
    @include bg_pos;
    background-size: cover;
    background-image: url("./../img/relations_bg.png");
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

.projects{
    .section_header{
        margin-bottom: 20px;
        @media only screen and (max-width: 991px) {
            .text-right{
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    .projects_carousel{
        margin-bottom: 120px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 60px;
        }
        .c_nav{
            text-align: right;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    @include bg_pos;
                    cursor: pointer;
                    height: 54px;
                    width: 194px;
                    @media only screen and (max-width: 991px) {
                        width: 50%;
                    }
                    &.arr_left{
                        background-image:url("./../img/c_arr_left.svg");
                    }
                    &.arr_right{
                        background-color: #e9e9e9;
                        background-image:url("./../img/c_arr_right.svg");
                    }
                }
            }
        }
        .carousel_content{
            overflow: hidden;
            > ul{
                position: relative;
                white-space: nowrap;
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 32%;
                    margin-right: 2%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .projects_list{
        margin-bottom: 83px;
        text-align: center;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        > ul{
            margin:0 -20px;
            > li{
                text-align: left;
                padding: 0 20px;
                margin-bottom: 40px;
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .project_box{
        width: 100%;
        height: 0;
        padding-bottom: 69%;
        position: relative;
        overflow: hidden;
        &:hover{
            .image{
                transform: scale(1.1);
            }
        }
        .image{
            @include fill;
            @include bg_pos;
            background-size: cover;
            @include anim;
        }
        .project_name{
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            p{
                background: $white;
                padding-left: 63px;
                padding-right: 40px;
                display: inline-block;
                font-size:24px;
                line-height: 34px;
                min-height: 70px;
                color: $font_color1;
                text-transform: uppercase;
                position: relative;
                padding-left: 0;
                vertical-align: middle;
                padding-top: 18px;
                @media only screen and (max-width: 1500px) {
                    font-size: 20px;
                    line-height: 30px;
                }
                @media only screen and (max-width: 1400px) {
                    font-size: 15px;
                    line-height: 25px;
                    padding-top: 23px;
                }
                @media only screen and (max-width: 1200px) {
                    font-size: 12px;
                    line-height: 22px;
                }
                &:before{
                    margin-right: 20px;
                    content: '';
                    width: 35px;
                    border-bottom: 2px solid $accent_color1;
                    //position: absolute;
                    //top: 50%;
                    //left: 0;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}

.split_boxes{
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
    > ul{
        > li{
            width: 100%;
            margin-bottom: 10vw;
            @media only screen and (max-width: 991px) {
                text-align: center;
                .desc{
                    margin-bottom: 30px;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            &.box_right{
                display: flex;
                @media only screen and (max-width: 991px) {
                    flex-flow: column;
                }
                > div{
                    @media only screen and (max-width: 991px) {
                        align-items: stretch;
                    }
                    &.desc_side{
                        width: 49%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            order: 1;
                        }
                        .desc{
                            padding-left: 23%;
                            @media only screen and (max-width: 991px) {
                                padding-left: 0;
                            }
                        }
                    }
                    &.images_side{
                        width: 51%;
                        position: relative;

                        height: 85vh;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            height: auto;
                            order: 2;
                        }
                        .moving_bg{
                            @include fill;
                            @media only screen and (max-width: 991px) {
                                position: relative;
                            }
                            .image{
                                @include bg_pos;
                                background-size: contain;
                                position: absolute;
                                @media only screen and (max-width: 991px) {
                                    width: 100% !important;
                                    position: relative;
                                    left: 0 !important;
                                    right: 0;
                                    top: 0 !important;
                                    height: 300px !important;
                                    margin-bottom: 15px;
                                }

                                &.img1{
                                    top: 0;
                                    left: 0;
                                    width: 38.66vw;
                                    //height: 22.4vw;
                                    height: 56.55%;
                                }
                                &.img2{
                                    //top: calc(22.4vw + 40px);
                                    left: 7.84vw;
                                    width:18.23vw;
                                    //height: 15.12vw;
                                    height:38.122%;
                                    top: calc(56.55% + 40px);
                                }
                                &.img3{
                                    top: 100px;
                                    right: 0;
                                    width: 23vw;
                                    //height: 27.92vw;
                                    height: 70.5%;
                                }
                            }
                        }
                    }
                }
            }
            &.box_left{
                display: flex;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                > div{

                    &.desc_side{
                        width: 42%;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        .desc{
                            padding-right: 21%;
                            margin-bottom: 15%;
                            @media only screen and (max-width: 991px) {
                                padding-right: 0;
                                margin-bottom: 30px;
                            }
                        }
                    }
                    &.images_side{
                        width: 58%;
                        position: relative;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        > .first_plan{
                            width: 100%;
                            .image{
                                width: 66.5%;
                                height: 0;
                                padding-bottom: 41%;
                                position: relative;
                                z-index: 2;
                                margin: 0 auto;

                                @include bg_pos;
                                background-size: cover;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    height: 300px;
                                    padding-bottom: 0;
                                }
                            }
                        }
                        .moving_bg{
                            @include fill;
                            @media only screen and (max-width: 991px) {
                                position: relative;
                            }
                            .image{
                                @include bg_pos;
                                background-size: cover;
                                position: absolute;

                                @media only screen and (max-width: 991px) {
                                    position: relative;
                                    top: 0!important;
                                    left: 0 !important;
                                    width: 100% !important;
                                    height: 300px !important;
                                    margin-bottom: 15px;
                                }
                                &.img1{
                                    top: 45px;
                                    left: 0;
                                    width:7.1vw;
                                    height: 8.77vw;
                                }
                                &.img2{
                                    top: calc(8.77vw + 37px + 45px);
                                    left: 0;
                                    width:18.23vw;
                                    height: 15.12vw;
                                }
                                &.img3{
                                    top: 100px;
                                    right: 0;
                                    width:23vw;
                                    height: 21.7vw;
                                }
                            }
                        }
                    }
                }
            }
            .short_desc{
                margin-bottom: 70px;
                &.about_company{
                    font-size: 24px;
                    line-height: 40px;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.events{
    margin-top: -10vw;
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: url("./../img/events_bg.jpg");
    //background-attachment: fixed;
    padding-top: 10vw;
    padding-bottom: 125px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 60px;
    }
    @media only screen and (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .desc{
        color:$white;
        &.desc_middle{
            margin-bottom: 60px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
        }
        &.desc_right{
            padding-left: 50px;
            @media only screen and (max-width: 991px) {
                padding-left: 0;
            }
            .default_font{
                margin-bottom: 90px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .image{
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        @include bg_pos;
        background-size: cover;
        box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .cover{
            @include fill;
            @include vertical;
            cursor: pointer;
            > div{
                width: 100%;
                text-align: center;
            }
        }
    }
    .view_movie_btn{
        display: inline-block;
        font-size: 18px;
        line-height: 30px;
        color: #bcbcbc;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            left: -46px;
            bottom: 100%;
            height: 40px;
            width: 95px;
            @include bg_pos;
            background-image: url("./../img/movie_arr.svg");
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        strong{
            color:$white;
        }
    }
}

.page{
    overflow: hidden;
}

.page_footer{
    .carousel_holder{
        background: $dark_theme;
        padding:47px 0 47px 100px;
        position: relative;
        margin-bottom: 120px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 60px;
        }
        @media only screen and (max-width: 1200px) {
           padding: 37px 0 37px 50px;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
            margin-bottom: 30px;
        }
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            width: 200%;
            height: 100%;
            background: $dark_theme;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .partners_carosuel{
            display: table;
            height:80px;
            width: 100%;
            @media only screen and (max-width: 991px) {
                height: auto;
                display: block;
            }
            > div{
                display:table-cell;
                vertical-align:middle;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
                &.title{
                    width:280px;
                    border-right: 1px solid $accent_color1;
                    color: $white;
                    font-size: 36px;
                    @media only screen and (max-width: 1200px) {
                        width: 250px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                        border-right: none;
                    }
                }
                &.carousel_content{
                    width: calc(100% - (280px + 55px));
                    overflow: hidden;
                    padding-left: 15px;
                    padding-right: 35px;
                    @media only screen and (max-width: 1200px) {
                        width: calc(100% - (250px + 55px));
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding: 30px 0;
                    }
                    > ul{
                        position: relative;
                        white-space: nowrap;
                        > li{
                            display: inline-block;
                            vertical-align: middle;
                            width: 20%;
                            text-align: center;
                            @media only screen and (max-width: 1500px) {
                                width: 33.33%;
                            }
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                            &:hover{
                                img{
                                    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                                    filter: grayscale(0%);
                                }
                            }
                            img{
                                @include anim;
                                transform:scale(0.8);
                                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                                filter: grayscale(100%);
                            }
                        }
                    }
                }
                &.carousel_nav{
                    width:55px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        text-align: center;
                    }
                    ul{
                        li{
                            width: 50%;
                            display: inline-block;
                            vertical-align: middle;
                            cursor: pointer;
                            @media only screen and (max-width: 991px) {
                                &.arr_left{
                                    text-align: center;
                                }
                            }
                            &.arr_right{
                                text-align: right;
                                @media only screen and (max-width: 991px) {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.contact{
    height: 620px;
    display: flex;
    @media only screen and (max-width: 991px) {
        height: auto;
        display: block;
    }
    > div{

        .v_content{
            @include vertical;
            > div{
                width: 100%;
            }
        }
        .large_box{
            height: calc(100% - 125px);
            width: 100%;
            background: #e9e9e9;
            @media only screen and (max-width: 991px) {
                height: auto;
            }
            &.map_holder{
                position: relative;
                @media only screen and (max-width: 991px) {
                    height: 200px;
                }
                iframe{
                    @include fill;
                    border: none;
                }
                .map_list{
                    ul{
                        li{
                            display: none;
                            &.map_adress1{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .small_box{
            height: 125px;
            width: 100%;
            @media only screen and (max-width: 991px) {
                height: auto;
            }
        }
        .omd_info{
            @media only screen and (max-width: 991px) {
                padding: 30px 0;
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 80px;
                    font-size: 13px;
                    line-height: 25px;
                    color: $font_color2;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 15px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 10px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 30px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    .social{
                        padding-left: 24px;
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-image: url("./../img/social_icon1.svg");
                        a{
                            color:$font_color2;
                            @include anim;
                            &:hover{
                                color: $accent_color1;
                                text-decoration: none;
                            }
                        }
                    }
                    strong{
                        font-size: 12px;
                        color: $font_color1;
                    }
                    &:last-child{
                        margin-right: 0;
                        padding-left: 80px;
                        border-left: 1px solid #bfbfbf;
                        @media only screen and (max-width: 1500px) {
                            padding-left: 40px;
                        }
                        @media only screen and (max-width: 1200px) {
                            padding-left: 20px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                            border-left: none;
                        }
                    }
                }
            }
        }
        .contact_data{
            padding-left: 100px;
            padding-top: 35px;
            @media only screen and (max-width: 1500px) {
                padding-top: 35px;
                padding-left: 50px;
            }
            @media only screen and (max-width: 991px) {
                padding-left: 0;
                text-align: center;
                padding-bottom: 45px;
            }
            .footer_header{
                font-size: 70px;
                color: $font_color1;
                @media only screen and (max-width: 1500px) {
                    font-size: 50px;
                }
            }
            .contact_data_toggles{
                margin-bottom: 35px;
                padding-top: 15px;
                > ul{
                    display: inline-block;
                    border-bottom: 2px solid $white;
                    > li{
                        display: inline-block;
                        vertical-align: top;
                        &.toggle_active{
                           p{
                               color: $font_color1;
                               font-weight: 700;
                               &:after{
                                   width: 100%;
                                   left: 0;
                               }
                           }
                        }
                        p{
                            padding: 0 20px;
                            position: relative;
                            font-size: 18px;
                            line-height: 27px;
                            cursor: pointer;
                            padding-bottom: 8px;
                            color:#6f6f6f;
                            font-weight: 400;
                            @include anim;
                            &:hover{
                                color: $font_color1;
                                font-weight: 700;
                                &:after{
                                    width: 100%;
                                    left: 0;
                                }
                            }
                            &:after{
                                content: '';
                                left: 50%;
                                width: 0;
                                border-bottom: 3px solid $accent_color1;
                                position: absolute;
                                bottom: -2px;
                                @include anim;
                            }
                        }
                    }
                }
            }
            .toggles_content{
                padding-left: 63px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                }
                > ul{
                    > li{
                        display: none;
                        > ul{
                            > li{
                                padding-left: 35px;
                                background-repeat: no-repeat;
                                background-position: left top 5px;
                                font-size: 18px;
                                line-height: 24px;
                                margin-bottom: 25px;
                                min-height: 28px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                    padding-top: 35px;
                                    background-position: center top;
                                }
                                p,a{
                                    color:$font_color1;
                                }
                                &.adress{
                                    background-image:url("./../img/pin_icon.svg");
                                }
                                &.phone{
                                    background-image:url("./../img/phone_icon.svg");
                                }
                                &.mail{
                                    background-image:url("./../img/mail_icon.svg");
                                }
                            }
                        }
                    }
                }
            }
        }
        &.contact_left_side{
            width: 46%;
            @media only screen and (max-width: 1200px) {
                width: 50%;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        &.contact_right_side{
            width: 54%;
            @media only screen and (max-width: 1200px) {
                width: 50%;
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            .how_to_drive{
                padding-left: 82px;
                @media only screen and (max-width: 1500px) {
                    padding-left: 42px;
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    margin-bottom: 30px;
                }
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        width: 50%;
                        .pin_text{
                            display: inline-block;
                            font-size: 18px;
                            color:#6f6f6f;
                            line-height: 25px;
                            padding-left: 52px;
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-image: url("./../img/how_to_pin_icon.svg");
                            @media only screen and (max-width: 991px) {
                                font-size: 12px;
                            }
                        }
                        .to_up{
                            cursor: pointer;
                            display:inline-block;
                            letter-spacing: 2px;
                            font-size: 12px;
                            text-transform:uppercase;
                            color:#6f6f6f;
                            padding-left: 32px;
                            background-position:left center;
                            background-repeat: no-repeat;
                            background-image: url("./../img/to_up.svg");
                        }
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

.general_footer{
    padding: 75px 0;
    font-size: 13px;
    text-transform: uppercase;
    color: $font_color2;
    @media only screen and (max-width: 1500px) {
        padding: 45px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    .menu_box{
        padding-top: 2px;
        nav{
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 60px;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 30px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 18px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 100%;
                        text-align: center;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 12px;
                        text-transform: uppercase;
                        color: $font_color1;
                        @include anim;
                        @media only screen and (max-width: 991px) {
                            line-height: 30px;
                            margin-bottom: 15px;
                        }
                        &:hover{
                            text-decoration: none;
                            color: $accent_color1;
                        }
                    }
                }
            }
        }
    }
    .devs{
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: middle;
                img{
                    margin-left: 14px;
                    margin-top: -5px;
                }
            }
        }
    }
}

.top{
    height: 440px;
    width: 100%;
    position: relative;
    background: #0a0a0a;
    overflow: hidden;
    .bg{
        @include fill;
        height: 175%;
        opacity: 0.15;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
    }
    .cover{
        @include fill;
        background-repeat: no-repeat;
        background-position: right top;
        background-image: url("./../img/top_cover.png");
        > div{
            @include fill;
            @include bg_pos;
            background-position:center bottom;
            background-size:cover;
            background-image:url("./../img/movie_cover.png");
            z-index: 2;
        }
    }
    .top_content{
        z-index: 3;
        @include fill;
        @include vertical;
        > div{
            text-align: center;
        }
        .default_font{
            color: $white;
        }
        .return_btn{
            margin-bottom: 20px;
        }
    }
}

.gallery{
    margin-bottom: 123px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 60px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 0;
    }
    .gallery_main_image{
        width: 100%;
        height: 0;
        padding-bottom: 40.4%;
        margin-bottom: 60px;
        position: relative;
        overflow: hidden;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
        .image{
            @include anim;
            @include bg_pos;
            @include fill;
            background-size: cover;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .grid_gallery{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        grid-gap: 60px;
        grid-auto-rows: minmax(12.5vw, auto);
        grid-auto-flow: dense;
        @media only screen and (max-width: 1500px) {
            grid-gap: 30px;
        }
        @media only screen and (max-width: 991px) {
            display: block;
        }
        .image_holder{
            position: relative;
            overflow: hidden;
            @media only screen and (max-width: 991px) {
                width: 100%;
                height: 0;
                padding-bottom: 40.4%;
                margin-bottom: 30px;
            }
            .image{
                background-size: cover;
                @include fill;
                @include bg_pos;
                @include anim;
                &.movie_box{
                    @include fill;
                    @include vertical;
                    cursor: pointer;
                    > div{
                        width: 100%;
                        text-align: center;
                    }
                    &:hover{
                        transform: scale(1);
                    }
                }
                &:hover{
                    transform: scale(1.1);
                }
            }
            &.small{
                grid-column-end: span 1;
                grid-row-end: span 1;
            }
            &.large {
                grid-column-end: span 2;
                grid-row-end: span 2;
            }
        }
    }
}

.return_btn{
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 44px;
    background-position:left center;
    background-repeat: no-repeat;
    background-image: url("./../img/return_arr.svg");
    color: $font_color1;
    @include anim;
    &.white{
        color: $white;
    }
    &:hover{
        text-decoration: none;
        color: $accent_color1;
    }
}

.realizations_details{
    .desc{
        margin-bottom: 90px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 45px;
        }
        .default_font{
            text-align: justify;
            text-align-last: center;
            color: $font_color2;
        }
    }
}

.menu_window{
    height: 100vh;
    width: 100%;
    z-index: 10000;
    background: $dark_theme;
    position: fixed;
    background-position: right top;
    background-repeat: no-repeat;
    background-image: url("./../img/menu_window_bg.png");
    background-size: auto 100%;
    display: none;
    @media only screen and (max-width: 991px) {
        overflow-y: scroll;

        /* width */
        &::-webkit-scrollbar {
            width: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #adadad;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $accent_color1;
        }
    }
    .menu_header{
        padding-top: 38px;
        @media only screen and (max-width: 991px) {
            padding-top: 10px;
        }
        .row{
            > div{
                display: inline-block;
                vertical-align: middle;
                float: none;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                img{
                    cursor:pointer;
                    @media only screen and (max-width: 991px) {
                        max-width: 100px;
                        &.close_menu{
                            max-width: 30px;
                        }
                    }
                }
            }
        }
    }
    .menu_content{
        //padding-top: 80px;
        padding-top: 4vw;
        .default_font{
            margin-bottom: 50px;
            color: #adadad;
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
            h3{
                font-weight: 300;
                line-height: 58px;
                @media only screen and (max-width: 991px) {
                    font-size: 18px;
                    line-height: 38px;
                }
            }
        }
        .contact_box{
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
            ul{
                li{
                    padding-left: 47px;
                    background-repeat: no-repeat;
                    background-position: left top 5px;
                    margin-bottom: 40px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;
                        min-height: 29px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        font-size: 21px;
                        color: #adadad;
                        @include anim;
                        @media only screen and (max-width: 991px) {
                            font-size: 18px;
                        }
                        &:hover{
                            text-decoration: none;
                            color: $accent_color1;
                        }
                    }
                    &.phone{
                        background-image: url("./../img/phone_icon.svg");
                    }
                    &.mail{
                        background-image: url("./../img/mail_icon.svg");
                    }
                    &.social{
                        background-image: url("./../img/social_icon_menu.png");
                    }
                }
            }
        }
        .page_menu{
            //background-image: url("./../img/menu_cover.png");
            background-size: auto 100%;
            background-position: left center;
            background-repeat: no-repeat;
            padding-left: 29%;
            border-left: 1px solid #404040;
            @media only screen and (max-width: 991px) {
                padding-left: 0;
                background-image: none;
                border-left: none;
            }
            > ul{
                > li{
                    &:hover{
                        > div{
                            a{
                                text-decoration: none;
                                color: $white;
                            }
                            &:after{
                                width: 100%;
                            }
                        }
                    }
                    > div{
                        //padding-left: 72px;
                        position: relative;
                        &.on{
                            a{
                                text-decoration: none;
                                color: $white;
                            }
                            &:after{
                                width: 100%;
                            }
                        }
                        &:after{
                            @include anim;
                            content: '';
                            width: 0;
                            left: 0;
                            top: 50%;
                            border-top: 3px solid $accent_color1;
                            position: absolute;
                        }
                        @media only screen and (min-width: 991px) {
                            padding-left: 3.7vw;
                        }
                        a{
                            color:#a6a6a6;
                            font-weight: 700;
                            //font-size: 42px;
                            //line-height: 88px;
                            @include anim;
                            font-size:2.2vw;
                            line-height: 4.6vw;
                            @media only screen and (max-width: 991px) {
                                font-size: 18px;
                                line-height: 38px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.short_desc_holder{
    @media only screen and (max-width: 991px) {
        display: none !important;
    }
}

.carosuel_content{
    li{
        @media only screen and (max-width: 991px) {
            position: relative;
            .image{
                opacity: 1!important;
                box-shadow:none !important;
            }
            .cover{
                display: none !important;
            }
        }
    }
}

.rwd_link{
    display: none;
    @media only screen and (max-width: 991px) {
        @include fill;
        z-index: 5;
        display: block;
    }
}

.carousel_box{
    a{
        @media only screen and (max-width: 991px) {
            display: none !important;
        }
    }
}

.rwd_arrs{
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
        padding-top: 30px;
    }
    .carousel_nav1{
        width: 100%;
        > div{
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            width: 50%;
        }
    }
}

.small_gallery{
    padding-bottom: 70px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 0;
    }
    .small_gallery_list{
        text-align: center;
        > ul{
            margin: 0 -30px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -15px;
            }
            > li{
                width: 33.33%;
                padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 30px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 15px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                .small_gallery_box{
                    width: 100%;
                    height: 0;
                    padding-bottom: 65.5%;
                    box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
                    position: relative;
                    &.image_box{
                        .img{
                            @include fill;
                            @include vertical;
                            background-size: cover;
                        }
                    }
                    &.movie_box{
                        .movie_cover{
                            @include vertical;
                            @include fill;
                           > div{
                               width: 100%;
                               text-align: center;
                           }
                        }
                    }
                }
            }
        }
    }
}
